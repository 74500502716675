import React, { UIEvent, useEffect, useState, useContext } from 'react'
import Footer from '@components/footer'
import Header from '@components/header'
import Nav from '@components/nav'
import Layout from '../../../components/layouts'
import { graphql, Script, ScriptStrategy } from 'gatsby'
import Blog from '@components/blogPage'
import { ContentContext } from '../../../components/layouts'
import BlogByCategoryContent from '@components/blogByCategoryContent'

export const pageQuery = graphql`
  query NanodevBlogCategory($category: String) {
    allStrapiNanodevBlog(filter: { post_category: { eq: $category } }) {
      nodes {
        id
        post_at
        post_author
        post_category
        post_description
        post_id
        post_image {
          formats {
            large {
              url
            }
          }
        }
        post_link
        post_title
        localizations {
          data {
            attributes {
              locale
              post_description
              post_title
            }
            id
          }
        }
      }
    }
  }
`

const byCategoryView = ({ data }: { data: any }) => {
  const { lang, translate, setLanguage } = useContext(ContentContext)
  const isBrowser = typeof window !== 'undefined' ? true : false
  isBrowser ? document.body.setAttribute('data-bs-spy', 'scroll') : null
  const [pluginsLoaded, setPluginsLoaded] = useState(false)
  const [jqueryLoaded, setJQueryLoaded] = useState(false)
  const [svgInjectLoaded, setSvgInjectLoaded] = useState(false)

  return (
    <Layout>
      <div className="content-wrapper">
        <Nav />
        <BlogByCategoryContent data={data.allStrapiNanodevBlog.nodes} />
      </div>
      <Footer />
      <div className="progress-wrap">
        <svg
          className="progress-circle svg-content"
          width="100%"
          height="100%"
          viewBox="-1 -1 102 102"
        >
          <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
      </div>
      {isBrowser && (
        <Script
          src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js"
          integrity="sha512-894YE6QWD5I59HgZOGReFYm4dnWc1Qt5NtvYSaNcOP+u1T9qYdvdihz0PPSiiqn/+/3e7Jo4EaG7TubfWGUrMQ=="
          crossOrigin="anonymous"
          onLoad={() => setJQueryLoaded(true)}
        />
      )}

      {jqueryLoaded && isBrowser && (
        <Script
          src="/plugins.js"
          key={'pluginjs'}
          strategy={ScriptStrategy.postHydrate}
          onLoad={() => setPluginsLoaded(true)}
        />
      )}

      {jqueryLoaded && isBrowser && (
        <Script
          src="/svg-inject.min.js"
          key={'svg-inject'}
          onLoad={() => setSvgInjectLoaded(true)}
        />
      )}
      {jqueryLoaded && isBrowser && (
        <Script src="/headhesive.min.js" key={'headhesive'} />
      )}
      {jqueryLoaded && isBrowser && (
        <Script src="/scrollCue.min.js" key={'scrollCue'} />
      )}
      {jqueryLoaded && isBrowser && (
        <Script src="/glightbox.min.js" key={'glightbox'} />
      )}
      {jqueryLoaded && isBrowser && (
        <Script src="/owl.carousel.min.js" key={'owl.carousel'} />
      )}
      {svgInjectLoaded && isBrowser && <Script src="/theme.js" key={'theme'} />}
    </Layout>
  )
}

export default byCategoryView

export const Head = ({ author }: { author: string }) => {
  return (
    <>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="title" property="og:title" content="nano dev inc" />
      <meta name="type" property="og:type" content="website" />
      <meta
        name="image"
        property="og:image"
        content="https://ashy-sand-0c4dc6a10.2.azurestaticapps.net/nanologo.png"
      />
      <meta name="url" property="og:url" content="https://www.nanodev.ca" />
      <meta
        name="description"
        property="og:description"
        content="WE PROVIDE CREATIVE AND INNOVATIVE SOLUTIONS"
      />
      <meta property="og:site_name" content="nano dev inc"></meta>

      <meta
        name="keywords"
        content="IT, Software, business, corporate, creative, marketing, AI, modern, startup, blog, infos, newsletter_mail"
      />
      <meta name="author" content="NanoDev" />
      <title>{`NanoDev | Blog Posts by Category`}</title>
    </>
  )
}
